'use strict'

let brandsNames = [];
let brandsObject = {};
let stockArr = []

let priceArr = []
let priceObject = {}


export{
    brandsNames,
    brandsObject,
    stockArr,
    priceArr,
    priceObject
}